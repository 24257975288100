/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const USER_LOGIN_INIT = 'LoginPage/USER_LOGIN_INIT';
export const USER_LOGIN_SUCCESS = 'LoginPage/USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'LoginPage/USER_LOGIN_ERROR';
export const USER_LOGOUT = 'LoginPage/USER_LOGOUT';
export const USER_UPDATE = 'LoginPage/USER_UPDATE';
export const USER_ROQ_OFFLINE_DATA_EXISTS = 'LoginPage/USER_ROQ_OFFLINE_DATA_EXISTS';
export const CUSTOMER_LOGO_IMAGE = 'LoginPage/LOGO_IMAGE';