import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import Button from "../../components/Button";
import poweredByLogo from "../../assets/img/logo.png";
import { toast } from "react-toastify";
import PWAPrompt from "react-ios-pwa-prompt";

import { login, fetchCustomerLogo } from "./actions";
// the hoc
import { withTranslation } from "react-i18next";
import { checkEmptyObject } from "../../utils";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        username: "",
        password: "",
      },
      logo: this.props.logoImage
    };
  }
  componentDidMount() {
    let { isLoggedIn } = this.props;
    if (isLoggedIn) {
      /* 
       * Default landing page is 'home' page where app have all menu buttons
       * Landing user on project selection page, if a project is not selected, landing on default landing page.
       */
      let landingPage = "/home";
      if (checkEmptyObject(this.props.selectedProject)) {
        landingPage = "/projects";
      }
      this.props.redirect(landingPage);
    }
    
    /*
     * Customer's logo 
     * 1. Display logo image from local cache (If exists).
     * 2. Else fetch through API and cached it (SUCCESS) to avoid more hits.
     * 3. On API FAIL display the static OciusX logo from assets (Default).
     */
    this.props.loadLogo().then(e => {
      let staticLogo = poweredByLogo;
      let cachedLogo = this.props.logoImage;
      this.setState({ logo: cachedLogo !== "" ? cachedLogo : staticLogo });
    });

  }
  handleChange = (e) => {
    let user = { ...this.state.user };
    user[e.target.name] = e.target.value;
    this.setState({ user });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.user.username || !this.state.user.password) {
      toast.error("Please fill the form completely!");
    } else {
      let user = this.state.user;
      this.props.login(user, this.props.selectedProject);
    }
  };

  render() {
    let { isLoading, t } = this.props;
    return (
      <>
        <div className="login-parent-container">
          <div className="container login-child-container">
            <div className="row justify-content-center align-items-center">
              <div className="col col-lg-6 maximum-width">
                <div className="form-group text-center mb-2 customer-logo">
                  <img alt="Customer logo" src={this.state.logo} />
                </div>
                <div className="bg-box login-padding-box form-box">
                  <form className="center-third" onSubmit={this.handleSubmit}>
                    <div className="form-group mt-1 username-margin-bottom">
                      <label htmlFor="username">{t("label.Username")}</label>
                      <input
                        className="form-control"
                        name="username"
                        placeholder={t("label.Username")}
                        type="text"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.user.username}
                      />
                    </div>
                    <div className="form-group ">
                      <label htmlFor="password">{t("label.Password")}</label>
                      <input
                        className="form-control"
                        name="password"
                        placeholder={t("label.Password")}
                        type="password"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.user.password}
                      />
                    </div>

                    <div className="form-group text-center mt-4 mb-2">
                      <Button type="submit" className="btn btn-primary" loading={isLoading}>
                        {t("label.Login")}
                      </Button>
                    </div>
                  </form>
                </div>
                <div className="mt-2 pull-right">
                  <label htmlFor="lblPoweredBy">Powered By :&nbsp;</label>
                  <img alt="Powered by logo" className="powered-by-logo" src={poweredByLogo} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <PWAPrompt timesToShow={90} delay={500} permanentlyHideOnDismiss={false}
          copyTitle={t("label.Add_to_home_screen")}
          copyClosePrompt={t("text.Cancel")}
          copyBody={t("label.Add_to_home_screen_note")}
          copyShareButtonLabel={t("label.Add_to_home_screen_step1")}
          copyAddHomeButtonLabel={t("label.Add_to_home_screen_step2")} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.auth.isLoading,
  selectedProject: state.selectProject.selectedProject,
  logoImage: state.auth.logoImage
});

const mapDispatchToProps = (dispatch) => ({
  login: (user, selectedProject) => dispatch(login(user, selectedProject)),
  redirect: (path) => dispatch(push(path)),
  loadLogo: () => dispatch(fetchCustomerLogo())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginPage));
