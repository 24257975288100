import * as constant from "./constants";
import { push } from "connected-react-router";
import axios from "axios";
import { baseAuth, dispatchPromise, checkEmptyObject } from "../../utils";
import { toast } from "react-toastify";
import { saveDataInStore } from "../../offline/db";
import { OBJECTSTORE_TEAMS } from "../../offline/os/common/teams";
import { OBJECTSTORE_CODE_LIST_TYPES, assignCodeListTypeToLocalStore } from "../../offline/os/common/codeListTypes";
import { TEAM_SELECT } from "../ModuleInitials/Team/constants";
import { DATABASE_NAME } from "../../offline/config";
import { isRoqOfflineEntryExists } from "../../offline/os/reportingOfQuantities/entries";
import { LOGOUT_CLEAR_MODULE } from "../Home/constants";
import { LOGOUT_CLEAR_PROJECT } from "../ModuleInitials/Project/constants";
import { LOGOUT_CLEAR_TEAM } from "../ModuleInitials/Team/constants";
import { LOGOUT_CLEAR_CODE_LIST } from "../ModuleInitials/CodeListType/constants";

export const login = (user, selectedProject) => dispatch => {
  // Initiating action which will display the loader image on UI
  dispatch({ type: constant.USER_LOGIN_INIT });
  let token = baseAuth(user);
  axios.defaults.headers.common["Authorization"] = `Basic ${token}`;
  let loginUser = {}
  // axios class which will execute API and execute a promise on success.
  axios
    .post("/login")
    .then(res => {
      loginUser = res.data;
      // Save information about the logged in user and login on home page
      localStorage.setItem("user", JSON.stringify(loginUser));
      localStorage.setItem("token", token);
      // Get a selected team and a selected code type and cached them for further use in related components
      let teamsApiUrl = `/teams?companyId=${loginUser.companyId}&isActive=true`;
      // We then fetch the RoQ price list by parentProjectId instead project id.
      let codeListTypesApiUrl = `/roqpricelists?projectId=${checkEmptyObject(selectedProject) ? 0 : selectedProject.parentProjectId}&expand=codelisttypeName&forUserRegistration=true`;
      // Return anothor axios class which will execute API and execute a promise on success.
      return axios.all([axios.get(teamsApiUrl), axios.get(codeListTypesApiUrl)])
    })
    .then(axios.spread((teamsResponse, codeTypesResponse) => {
      let teams = teamsResponse.data;
      let codeListTypes = codeTypesResponse.data;
      codeListTypes = codeListTypes.map(x => x = { ...x, name: x.codeListTypeName });
      // Save the teams and code types to the offline store.
      saveDataInStore(OBJECTSTORE_TEAMS, teams);
      saveDataInStore(OBJECTSTORE_CODE_LIST_TYPES, codeListTypes);
      /*
       * The user should not be associated with the default team, if there are options to select a real team
       * Condition-1: If company has only one team (default) associated with user then, we should never ask the user to change team.
       * Condition-2: If there are more than one team, but user still wants to be in default team, then he can.
       * Else: Make variable empty.
       */
      if (teams.some(team => team.id === loginUser.teamId)) {
        let associatedTeam = teams.filter(team => team.id === loginUser.teamId)[0];
        dispatch({
          type: TEAM_SELECT,
          payload: teams.length === 1 && associatedTeam.default ? associatedTeam :
            teams.length > 1 && !associatedTeam.default ? associatedTeam : {}
        });
      }
      assignCodeListTypeToLocalStore(loginUser.codeListTypeId, codeListTypes, dispatch);
      // Redirect the user to the project selection component
      dispatch({
        type: constant.USER_LOGIN_SUCCESS,
        payload: { user: loginUser }
      });
      dispatch(push("/projects"));
    }))
    .catch(error => {
      // On failed login, set auth to null on axios object.
      axios.defaults.headers.common["Authorization"] = null;
      dispatch({ type: constant.USER_LOGIN_ERROR, payload: error });
      // Status: 401 =  Unauthorized
      if (error.response && error.response.status === 401) {
        // If API returned any error msg, then show it. Otherwise show, default msg "Wrong username or password". 
        toast.error(!error.response.data ? "Wrong username or password" : error.response.data);
      }
    });
};
/*
 * When log out, we are clearing the cached data (including the Index DB). 
 * This needs to be done according to data laws as well. 
 * But, it could be possible that the user has got offline data (which has not yet synced) and if the user logs out then that will be lost. 
 * And we will never be able to save that on the server. 
 * So, we should display some warning or pop-up information when this case occurs.
 * On confirm: performLogout
 * On Cancel: declineLogout
 */
export const logout = () => dispatch => {
  isRoqOfflineEntryExists()
    .then((exists) => {
      if (!exists) {
        dispatch(performLogout());
      }
      else {
        dispatch({
          type: constant.USER_ROQ_OFFLINE_DATA_EXISTS,
          payload: true
        });
      }
    });
}
export const declineLogout = () => dispatch => {
  dispatch({
    type: constant.USER_ROQ_OFFLINE_DATA_EXISTS,
    payload: false
  });
}
export const performLogout = () => dispatch => {
  axios
    .get("/logout")
    .then(res => {
      dispatch(clearDataOnLogout());
    })
    .catch(error => {
      toast.error("Something went wrong");
      if (!axios.defaults.headers.common["Authorization"]) {
        dispatch(push("/login"));
      }
      else if (error.response && (error.response.status === 401 || error.response.status === 400)) {
        // Clear data even if the call fails.
        dispatch(clearDataOnLogout());
      }
    })
};
export const clearDataOnLogout = () => dispatch => {
  // clear redux of initials
  dispatch({ type: LOGOUT_CLEAR_MODULE });
  dispatch({ type: LOGOUT_CLEAR_PROJECT });
  dispatch({ type: LOGOUT_CLEAR_TEAM });
  dispatch({ type: LOGOUT_CLEAR_CODE_LIST });
  dispatch({ type: constant.USER_LOGOUT });

      // Reset the axios auth to null when logging out
      axios.defaults.headers.common["Authorization"] = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("logoImage");

  //Remove index DB on log out
  window.indexedDB.deleteDatabase(DATABASE_NAME);

  dispatch(push("/login"));
}

export const updateUserSelectedLanguage = (user, lang) => dispatch => {
  // Prepare API Url to update user's selected language
  let url = `/users/${user.id}`;
  axios
    .patch(url, { languageCode: lang })
    .then(() => dispatch(updateUser(user)))
}

export const updateUser = (user) => dispatch => {
  // Prepare API Url to update user's info
  let url = `/users/${user.id}?expand=CompanyId,CompanyName,TeamId,TeamName,Roles,CodeListTypeId,Modules,LanguageCode`;
  axios
    .get(url)
    .then((res) => {
      // Update the logged-in user's information
      localStorage.setItem("user", JSON.stringify(res.data));
      dispatchPromise(dispatch, constant.USER_UPDATE, res.data);
    })
};

export const fetchCustomerLogo = () => dispatch => {
  let logo = "";
  if (localStorage.getItem("logoImage")) {
    logo = localStorage.getItem("logoImage");
  }

  return new Promise((resolve, reject) => {
    if (logo !== "") {
      // Dispatch customer's logo image from cached
      dispatchPromise(dispatch, constant.CUSTOMER_LOGO_IMAGE, logo);
      resolve();
    }
    else {
      // Fetch customer's logo image from API
      axios
        .get(`/loginpagelogo`)
        .then((res) => {
          // Dispatch and Cached customer's logo image (SUCCESS)
          localStorage.setItem("logoImage", res.data);
          dispatchPromise(dispatch, constant.CUSTOMER_LOGO_IMAGE, res.data);
          resolve();
        })
        .catch((error) => {
          // Dispatch empty string so that can display static logo (FAIL)
          dispatchPromise(dispatch, constant.CUSTOMER_LOGO_IMAGE, "");
          resolve();
        });
    }
  });
};



